var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form',{staticClass:"form",attrs:{"model":_vm.form,"label-width":"80px","size":"mini"}},[_c('el-form-item',{attrs:{"label":"分类名"}},[_c('el-input',{model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.title"}})],1),_c('el-form-item',{attrs:{"label":"简介"}},[_c('el-input',{model:{value:(_vm.form.intro),callback:function ($$v) {_vm.$set(_vm.form, "intro", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.intro"}})],1),_c('el-form-item',{attrs:{"label":"关联案例"}},[_c('el-select',{attrs:{"placeholder":"请选择关联的客户案例"},model:{value:(_vm.form.pro_cat_id),callback:function ($$v) {_vm.$set(_vm.form, "pro_cat_id", $$v)},expression:"form.pro_cat_id"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"图标"}},[_c('el-upload',{attrs:{"headers":{ token: _vm.token },"limit":1,"name":"thumbnail","action":_vm.imgBaseUrl + '/api/Project/upload',"list-type":"picture-card","multiple":false,"file-list":_vm.fileList_icon,"on-success":res => {
                        _vm.success(res, 'icon');
                    },"on-remove":() => {
                        _vm.remove('icon');
                    }}},[_c('i',{staticClass:"el-icon-plus"})])],1),_c('el-form-item',{attrs:{"label":"列表类图"}},[_c('el-upload',{attrs:{"headers":{ token: _vm.token },"limit":1,"name":"thumbnail","action":_vm.imgBaseUrl + '/api/Project/upload',"list-type":"picture-card","multiple":false,"file-list":_vm.fileList_list,"on-success":res => {
                        _vm.success(res, 'list_image');
                    },"on-remove":() => {
                        _vm.remove('list_image');
                    }}},[_c('i',{staticClass:"el-icon-plus"})])],1),_c('el-form-item',{attrs:{"label":"背景图"}},[_c('el-upload',{attrs:{"headers":{ token: _vm.token },"limit":1,"name":"thumbnail","action":_vm.imgBaseUrl + '/api/Project/upload',"list-type":"picture-card","multiple":false,"file-list":_vm.fileList_bg,"on-success":res => {
                        _vm.success(res, 'bg_image');
                    },"on-remove":() => {
                        _vm.remove('bg_image');
                    }}},[_c('i',{staticClass:"el-icon-plus"})])],1),_c('el-form-item',{attrs:{"label":"缩略图"}},[_c('el-upload',{attrs:{"headers":{ token: _vm.token },"limit":1,"name":"thumbnail","action":_vm.imgBaseUrl + '/api/Project/upload',"list-type":"picture-card","multiple":false,"file-list":_vm.fileList_little,"on-success":res => {
                        _vm.success(res, 'little_image');
                    },"on-remove":() => {
                        _vm.remove('little_image');
                    }}},[_c('i',{staticClass:"el-icon-plus"})])],1),_c('el-form-item',{attrs:{"label":"状态"}},[_c('el-switch',{attrs:{"active-color":"#7781f1","inactive-color":"#ff4949","active-text":"显示","inactive-text":"隐藏"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1),_c('el-form-item',{attrs:{"label":"置顶"}},[_c('el-switch',{attrs:{"active-color":"#7781f1","inactive-color":"#ff4949","active-text":"置顶","inactive-text":"正常排序"},model:{value:(_vm.form.is_top),callback:function ($$v) {_vm.$set(_vm.form, "is_top", $$v)},expression:"form.is_top"}})],1),_c('el-form-item',{attrs:{"label":"排序"}},[_c('el-input-number',{attrs:{"min":1,"max":9999,"label":"描述文字"},model:{value:(_vm.form.sort),callback:function ($$v) {_vm.$set(_vm.form, "sort", $$v)},expression:"form.sort"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }