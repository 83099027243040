<template>
    <div>
        <el-form :model="form" label-width="80px" size="mini" class="form">
            <el-form-item label="分类名">
                <el-input v-model.trim="form.title"></el-input>
            </el-form-item>
            <el-form-item label="简介">
                <el-input v-model.trim="form.intro"></el-input>
            </el-form-item>
            <el-form-item label="关联案例">
                <!-- <el-input v-model.trim="form.intro"></el-input> -->
                <el-select v-model="form.pro_cat_id" placeholder="请选择关联的客户案例">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="图标">
                <el-upload
                    :headers="{ token: token }"
                    :limit="1"
                    name="thumbnail"
                    :action="imgBaseUrl + '/api/Project/upload'"
                    list-type="picture-card"
                    :multiple="false"
                    :file-list="fileList_icon"
                    :on-success="
                        res => {
                            success(res, 'icon');
                        }
                    "
                    :on-remove="
                        () => {
                            remove('icon');
                        }
                    "
                >
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="列表类图">
                <el-upload
                    :headers="{ token: token }"
                    :limit="1"
                    name="thumbnail"
                    :action="imgBaseUrl + '/api/Project/upload'"
                    list-type="picture-card"
                    :multiple="false"
                    :file-list="fileList_list"
                    :on-success="
                        res => {
                            success(res, 'list_image');
                        }
                    "
                    :on-remove="
                        () => {
                            remove('list_image');
                        }
                    "
                >
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="背景图">
                <el-upload
                    :headers="{ token: token }"
                    :limit="1"
                    name="thumbnail"
                    :action="imgBaseUrl + '/api/Project/upload'"
                    list-type="picture-card"
                    :multiple="false"
                    :file-list="fileList_bg"
                    :on-success="
                        res => {
                            success(res, 'bg_image');
                        }
                    "
                    :on-remove="
                        () => {
                            remove('bg_image');
                        }
                    "
                >
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="缩略图">
                <el-upload
                    :headers="{ token: token }"
                    :limit="1"
                    name="thumbnail"
                    :action="imgBaseUrl + '/api/Project/upload'"
                    list-type="picture-card"
                    :multiple="false"
                    :file-list="fileList_little"
                    :on-success="
                        res => {
                            success(res, 'little_image');
                        }
                    "
                    :on-remove="
                        () => {
                            remove('little_image');
                        }
                    "
                >
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="状态">
                <el-switch
                    v-model="form.status"
                    active-color="#7781f1"
                    inactive-color="#ff4949"
                    active-text="显示"
                    inactive-text="隐藏"
                >
                </el-switch>
            </el-form-item>
            <el-form-item label="置顶">
                <el-switch
                    v-model="form.is_top"
                    active-color="#7781f1"
                    inactive-color="#ff4949"
                    active-text="置顶"
                    inactive-text="正常排序"
                >
                </el-switch>
            </el-form-item>
            <el-form-item label="排序">
                <el-input-number v-model="form.sort" :min="1" :max="9999" label="描述文字"></el-input-number>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import Cookies from "js-cookie";
import axios from "axios";

export default {
    props: ["pid", "setForm"],
    data() {
        return {
            form: {
                title: "",
                icon: "",
                intro: "",
                pid: "",
                status: true,
                is_top: false,
                bg_image: "",
                little_image: "",
                list_image: "",
                pro_cat_id: "",
                sort: 1,
            },
            fileList_icon: [],
            fileList_bg: [],
            fileList_little: [],
            fileList_list: [],
            token: "",
            options: [],
        };
    },
    created() {
        this.form.pid = this.pid;
        this.token = Cookies.get("token");
        this.getFl();

        // 编辑
        if (this.setForm.id) {
            this.form = {
                ...this.setForm,
                status: this.setForm.status == "1" ? true : false,
                is_top: this.setForm.is_top == "1" ? true : false,
            };
            this.fileList_icon = this.setForm.icon ? [{ name: "", url: this.setForm.icon }] : [];
            this.fileList_bg = this.setForm.bg_image ? [{ name: "", url: this.setForm.bg_image }] : [];
            this.fileList_little = this.setForm.little_image ? [{ name: "", url: this.setForm.little_image }] : [];
            this.fileList_list = this.setForm.list_image ? [{ name: "", url: this.setForm.list_image }] : [];
        }
    },
    methods: {
        getForm() {
            return {
                ...this.form,
                status: this.form.status ? 1 : 0,
                is_top: this.form.is_top ? 1 : 0,
            };
        },
        // 获取客户案例的所有一级分类
        async getFl() {
            let { data } = await axios.post("/api/Project/category");
            this.options = data.map(e => {
                return {
                    value: e.id,
                    label: e.category_title,
                };
            });
        },
        // 图片上传成功后
        success(url, key) {
            this.form[key] = axios.defaults.baseURL + url;
        },
        // 移除图片时
        remove(key) {
            this.form[key] = "";
        },
    },
};
</script>

<style lang="less" scoped>
.form {
    /deep/.el-switch__label.is-active {
        color: #7781f1;
    }
}
</style>
