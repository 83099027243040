<template>
    <div>
        <div class="page">
            <div class="page_header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>解决方案</el-breadcrumb-item>
                    <el-breadcrumb-item>分类</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="page_headerSecondary">
                <div></div>
                <div class="">
                    <!-- <button @click="openAdd(1)">添加一级分类</button> -->
                    <VyAddBtn @click.native="openAdd(1)"></VyAddBtn>
                </div>
            </div>
            <!-- 列表 -->
            <div class="page_content">
                <el-table :data="tableData" border style="width: 100%" row-key="id" @row-click="handleRowClick" ref="tab">
                    <el-table-column prop="title" align="center" label="分类名"> </el-table-column>
                    <el-table-column prop="sort" align="center" label="排序"> </el-table-column>
                    <el-table-column prop="status" align="center" label="状态">
                        <template slot-scope="{ row }">
                            <span :style="{ color: row.status ? '#b66dff' : '#fe7c96' }">{{ row.status ? "显示" : "隐藏" }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="is_top" align="center" label="置顶">
                        <template slot-scope="{ row }">
                            <span>{{ row.is_top ? "置顶" : "" }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" align="center" label="操作">
                        <template slot-scope="{ row }">
                            <div class="btns">
                                <div class="_add" v-if="row.level == 1">
                                    <button @click="openAdd(2, row.id)">添加子分类</button>
                                </div>
                                <div class="_operate">
                                    <button @click="openSet(row)">编辑</button>
                                </div>
                                <div class="_delete">
                                    <button @click="del(row)">删除</button>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <!-- 添加或编辑一级分类弹框 -->
        <el-dialog
            :title="title_one ? '编辑一级分类' : '添加一级分类'"
            :visible.sync="dialogShow_one"
            width="40%"
            :show-close="false"
            :before-close="bfc"
        >
            <el-form :model="form_one" label-width="80px">
                <el-form-item label="分类名">
                    <el-input v-model.trim="form_one.title"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-switch
                        v-model="form_one.status"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="显示"
                        inactive-text="隐藏"
                    ></el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow_one = false">取 消</el-button>
                <el-button type="primary" @click="play_one">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 添加或编辑二级分类弹框 -->
        <el-dialog
            :title="title_two ? '编辑二级分类' : '添加二级分类'"
            :visible.sync="dialogShow_two"
            width="50%"
            :show-close="false"
            :before-close="bfc"
        >
            <formEr v-if="dialogShow_two" ref="form_two" :pid="pid" :setForm="setForm"></formEr>
            <span slot="footer" class="dialog-footer">
                <el-button @click="qx_two">取 消</el-button>
                <el-button type="primary" @click="play_two">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import formEr from "./form2.vue";
import VyAddBtn from "@/components/customs/VyAddBtn.vue";

export default {
    components: { formEr, VyAddBtn },
    data() {
        return {
            tableData: [],
            // 一级分类表单
            form_one: {
                title: "",
                status: true,
            },
            // 添加二级分类时需要的pid
            pid: "",
            // 编辑二级分类时需要的form
            setForm: {},
            dialogShow_one: false,
            dialogShow_two: false,

            title_one: 0,
            title_two: 0,
        };
    },
    async created() {
        this.loading();
    },
    methods: {
        // 获取列表数据
        async getData() {
            let {
                data: { data },
            } = await axios.get("/api/solution/showone");
            return data;
        },
        // 加载
        loading() {
            this.getData().then(res => {
                res.forEach(el => {
                    el.level = 1;
                    el.children.forEach(e => {
                        e.level = 2;
                        e.children = [];
                    });
                });
                this.tableData = res;
            });
        },
        // 添加或编辑一级分类
        async play_one() {
            let f = qs.stringify({
                ...this.form_one,
                status: this.form_one.status ? 1 : 0,
            });
            if (this.form_one.id) {
                // 编辑
                var { data } = await axios.put("/api/solution/fupdate", f);
            } else {
                // 添加
                var { data } = await axios.post("/api/solution/fadd", f);
            }
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.dialogShow_one = false;
            this.loading();
        },
        // 添加或编辑二级分类
        async play_two() {
            let f = this.$refs.form_two.getForm();
            if (f.id) {
                // 编辑
                axios.post("/api/solution/supdate", qs.stringify(f)).then(({ data }) => {
                    if (data.code !== 200) {
                        this.$message.error(data.msg);
                    } else {
                        this.$message.success(data.msg);
                    }
                });
            } else {
                // 添加
                axios.post("/api/solution/sadd", qs.stringify(f)).then(({ data }) => {
                    if (data.code !== 200) {
                        this.$message.error(data.msg);
                    } else {
                        this.$message.success(data.msg);
                    }
                });
            }
            this.qx_two();
        },
        qx_two() {
            this.pid = "";
            this.setForm = {};
            this.dialogShow_two = false;
            this.loading();
        },
        openSet(row) {
            if (row.level == 1) {
                this.form_one = {
                    title: row.title,
                    status: row.status ? true : false,
                    id: row.id,
                };
                this.title_one = 1;
                this.dialogShow_one = true;
            } else if (row.level == 2) {
                this.title_two = 1;
                this.setForm = row;
                this.dialogShow_two = true;
            }
        },
        openAdd(level, pid) {
            if (level == 1) {
                this.form_one = {
                    title: "",
                    status: true,
                };
                this.title_one = 0;
                this.dialogShow_one = true;
            } else if (level == 2) {
                this.pid = pid;
                this.title_two = 0;
                this.dialogShow_two = true;
            }
        },
        // 删除
        async del(row) {
            if (row.children.length !== 0) return this.$message.error("子元素不为空无法删除！");
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;

            if (row.level == 1) {
                // 删除一级分类
                axios.delete("/api/solution/fdel", { params: { id: row.id } }).then(({ data }) => {
                    if (data.code !== 200) {
                        this.$message.error(data.msg);
                    } else {
                        this.$message.success(data.msg);
                    }
                });
            } else if (row.level == 2) {
                // 删除二级分类
                axios.delete("/api/solution/sdel", { params: { id: row.id } }).then(({ data }) => {
                    if (data.code !== 200) {
                        this.$message.error(data.msg);
                    } else {
                        this.$message.success(data.msg);
                    }
                });
            }

            this.loading();
        },
        // 点击行
        handleRowClick(row, column, event) {
            if (event.target.tagName === "BUTTON") return;
            this.$refs["tab"].toggleRowExpansion(row);
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_headerSecondary {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
        .btns {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}
/deep/.el-switch__label.is-active {
    color: #7781f1;
}
</style>
